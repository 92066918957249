/* Auth.css */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #F7F7F7;
}

.auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-input {
    width: 300px;
    padding: 10px 15px;
    margin: 5px 0;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    font-size: 16px;
}

.auth-button {
    width: 300px;
    padding: 10px 0;
    margin: 5px 0;
    border: none;
    border-radius: 4px;
    background-color: #333;
    color: #FFF;
    cursor: pointer;
    transition: background-color 0.3s;
}

.auth-button:hover {
    background-color: #555;
}

.auth-button:active {
    background-color: #111;
}

.google-login-button {
    display: flex;
    align-items: center;
    background-color: #4285F4;
    border: none;
    color: white;
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s;
}

.google-login-button:hover {
    background-color: #357ABD;
}

.google-icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}




.gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: min-content;
  }
  
  .gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }
  
  .gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  
  .gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity .218s;
    transition: opacity .218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .gsi-material-button:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }
  
  .gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
  }
  
  .gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
  }
  
  .gsi-material-button:not(:disabled):active .gsi-material-button-state, 
  .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: #303030;
    opacity: 12%;
  }
  
  .gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  }
  
  .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: #303030;
    opacity: 8%;
  }

  

  .divider-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    margin: 10px 0;
  }

  .divider {
    height: 1px;
    /*右寄せ*/
    width: 100%;
    background-color: #E0E0E0;
  }

  .divider-text {
    padding: 0 10px;
    color: #333;
    width: 100%;
    text-align: center;
  }

  